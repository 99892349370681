import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import backToTopIcon from "assets/backToTop.svg";

function Accesibility() {
  const data = [
    {
      title: "Future Improvements",
      description: [
        `Tomedes is committed to continually improving the accessibility of our website. We are dedicated to implementing enhancements and updates that will further enhance the user experience for all individuals.`,
      ],
    },
    {
      title: "Legal Compliance",
      description: [
        "Our efforts to improve accessibility align with applicable laws and regulations, including the Americans with Disabilities Act (ADA) and Section 508 of the Rehabilitation Act.",
      ],
    },
    {
      title: "Date of Last Update",
      description: [
        "This accessibility statement was last updated on September 21, 2023.",
        "We are dedicated to providing an accessible and inclusive online environment for all users. Your feedback is essential in helping us achieve this goal. Thank you for visiting Tomedes.com.",
      ],
    },
  ];
  return (
    <>
      <SEO
        title="Web Accessibility - Tomedes "
        description="Discover Tomedes' commitment to an inclusive digital experience, aligning with WCAG 2.1 Level AA, and value feedback to enhance usability for all."
        keywords=""
        slug="/legal/accesibility"
      />
      <Layout>
        <div div className="max-w-7xl p-4 mx-auto  py-12">
          <Breadcrumb path={["Legal Policies", "Accessibility Statement"]} />
          <div className="mt-[50px]">
            <h1 className="text-5xl md:leading-none leading-[50px] font-bold">
              Accessibility Statement
            </h1>
            <p className="font-opensans leading-8 mt-5">
              At Tomedes, we are committed to ensuring that our website is
              accessible to all individuals, including those with disabilities.
              We believe in providing an inclusive online experience for
              everyone, regardless of their abilities or the assistive
              technologies they use. We strive to adhere to the Web Content
              Accessibility Guidelines (WCAG) 2.1 Level AA standards to make our
              website as user-friendly and accessible as possible.
            </p>
          </div>
          <div className="mt-[60px]">
            <h1 className="text-2xl  font-primary font-bold ">
              Our Commitment to Accessibility
            </h1>
            <ul className="list-disc mt-8 ml-10  flex flex-col gap-3">
              <li>
                We are actively working on implementing a range of accessibility
                features and best practices to improve the usability of our
                website.
              </li>
              <li>
                Please note that our accessibility features are a work in
                progress, and we are actively working to ensure that our website
                remains in compliance with WCAG guidelines and standards.
              </li>
              <li>
                We provide ongoing accessibility training to our web development
                and content teams to promote awareness and understanding of
                accessibility principles.
              </li>
              <li>
                We welcome feedback from our users regarding accessibility
                issues and are committed to addressing any concerns promptly.
              </li>
            </ul>
          </div>
          <div className="mt-[60px]">
            <h1 className="text-2xl  font-primary font-bold ">
              Accessibility Features (Coming Soon)
            </h1>
            <ul className="font-opensans list-disc mt-8 ml-10 flex flex-col gap-3">
              <li>
                <span className="font-bold ">Keyboard Navigation:</span> We are
                in the process of ensuring our website can be navigated using
                only a keyboard, without relying on a mouse.
              </li>
              <li>
                <span className="font-bold font-opensans">
                  {" "}
                  Alternative Text:
                </span>
                We are working to provide descriptive alternative text for
                images to ensure that screen readers can convey the content
                accurately.
              </li>
              <li>
                <span className="font-bold font-opensans">
                  Contrast and Color:
                </span>{" "}
                We are making efforts to maintain sufficient contrast between
                text and background colors to enhance readability.
              </li>
              <li>
                <span className="font-bold font-opensans">
                  {" "}
                  Resizable Text:
                </span>{" "}
                Soon, users will be able to adjust the text size in their
                browser settings for greater legibility.
              </li>
              <li>
                <span className="font-bold font-opensans">
                  Skip to Content:
                </span>{" "}
                We are planning to provide a "Skip to Content" link to allow
                users to bypass repetitive navigation and jump to the main
                content.
              </li>
              <li>
                <span className="font-bold font-opensans">
                  {" "}
                  Forms and Links:
                </span>{" "}
                We are in the process of using accessible form fields and link
                text to help screen readers interpret and interact with our
                content effectively.
              </li>
            </ul>
          </div>

          <div className="rounded-lg mt-14 my-5 text-[#131313]">
            <h1 className="text-2xl font-primary font-bold leading-9 ">
              Feedback and Contact Information
            </h1>
            <p className="font-opensans leading-8 mt-5 ">
              We value your feedback and encourage you to report any
              accessibility issues you encounter while using our website. If you
              have questions, suggestions, or concerns related to accessibility,
              please contact us:
            </p>

            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Email: support@tomedes.com</span>
              <span>Phone: US: +1 985 239 0142 | UK: +44 (0)16 1509 6140</span>
              <span>Postal Address: </span>
              <span>Tomedes LLC</span>
              <span>9450 SW Gemini Dr #34540</span>
              <span>Beaverton, OR 97008-7105, United States</span>
            </div>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes Ltd.</span>
              <span>26 HaRokmim st. Azrieli Business Center,</span>
              <span>Building C, 7th floor, Holon 5885849</span>
              <span>Israel</span>
              <span>Call : +972 (0)72 220 0700</span>
            </div>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes Ltd.</span>
              <span>7 Bell Yard, London</span>
              <span>WC2A 2 JR</span>
              <span>United Kingdom</span>
            </div>
          </div>

          <div className="flex flex-col mt-[55px] gap-[60px]">
            {data.map((item, i) => (
              <div
                key={i}
                className="rounded-lg first:mt-8 last:mt-5 text-[#131313]"
              >
                <h1 className="text-2xl  font-primary font-bold ">
                  {item.title}
                </h1>
                {item?.description?.map((text, i) => (
                  <p key={i} className="font-opensans leading-8 mt-8 ">
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Accesibility;
